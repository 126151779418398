<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-04-19 08:47:36
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:50:45
-->
<template>
    <div class="giftInfo">
        <div class="content-wrap">
            <div class="btn">
                <el-button type="primary" @click="isAddGift = true">添加奖品</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" style="width: 100%;">
                <el-table-column align="center" label="奖品图片" prop="date">
                    <template slot-scope="scope">
                        <img class="image" :src="scope.row.image" alt="">
                    </template>
                </el-table-column>
                <el-table-column align="center" label="奖品名称" prop="name"></el-table-column>
                <el-table-column align="center" label="中奖人数" prop="quota"></el-table-column>
                <el-table-column align="center" label="创建时间" prop="create_time"></el-table-column>
                <el-table-column align="center" label="操作" width="380">
                    <template slot-scope="scope">
                        <el-button size="mini"
                        @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                        @click="handleDelete(scope.row)">删除</el-button>
                        <el-button size="mini" type="success" 
                        @click="handlePlay(scope.row)">开始抽奖</el-button>
                         <el-button size="mini" type="primary" 
                        @click="handleInfo(scope.row)">中奖名单</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 添加 -->
        <el-dialog
            :before-close="handleCloseGift" 
            :close-on-click-modal="false"
            :visible.sync="isAddGift" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加奖品</div>
            <div v-else class="title">编辑奖品</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="giftForm" :rules="rulesGift" ref="giftForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="奖品名称" prop="name">
                        <el-input v-model="giftForm.name" placeholder="请输入奖品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="中奖人数" prop="quota">
                        <el-input v-model="giftForm.quota" placeholder="请输入中奖人数"></el-input>
                    </el-form-item>
                    <el-form-item label="奖品图片" prop="image">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="8" :limit="1" :isShopTip="false" :isSizePx='true'
                                :btnDisplay="image.length<1" listType="picture-card"
                                v-model="image">
                            </aliyun-upload>
                            <div v-if="image.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="image" :src="image[0]"/>
                            </div>
                            <div>上传图片大小不超过10M ，图片尺寸为1：1</div>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="btnLoading" type="primary" @click="submitFormEdit">保 存</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import AliyunUpload from "@/components/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    data () {
        return {
            giftForm: {
                quota: '',
                name: '',
                image: ''
            },
            rulesGift: {
                name: [
                    { required: true, message: '请输入奖品名称', trigger: 'blur' },
                ],
                quota: [
                    { required: true, message: '请输入奖品数量', trigger: 'blur' }
                ],
                image: [
                    { required: true, message: '请上传奖品图片', trigger: 'blur' },
                ],
            },
            currentPage: 1,
            total: 0,
            image: [],
            tableData: [],
            cjloaidng: false,
            loading: false,
            isAddGift: false,
            addLoading: false,
            btnLoading: false,
            editId: -1,
            activity_id: '',
        }
    },
    watch: {
        image: {
            handler(newval, oldval) {
                if(newval.length > 0){
                    this.giftForm.image = newval[0]
                }
            },
            deep: true
        }
    },
    mounted () {
        this.activity_id = localStorage.getItem("infoId");
        this.getGiftList()
    },
    methods: {
        // 中奖名单
        handleInfo(row){
            localStorage.setItem('gufId',row.id)
            this.$router.push("/luckyList");
        },
        // 抽奖
        handlePlay(row){
            common.connect("/rafflev1/activity/lotteryInfo",{id: row.id},(res)=>{
                if(res.data.activity_prize_data.quota > res.data.activity_list_data.length){
                    this.$message.error('抽奖人数少于中奖次数，请重新设置');
                    return
                }
                localStorage.setItem('info',JSON.stringify(res.data))
                this.$router.push("/luckyDraw");
            });
        },
        // 奖品列表
        getGiftList(){
            this.loading = true;
            let params = {
                page: this.currentPage,
                activity_id: this.activity_id
            }
            common.connect("/rafflev1/activity/prizeIndex",params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        // 删除
        handleDelete(row){
            this.$confirm('此操作将永久删除该奖品, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/rafflev1/activity/prizeDelete",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getGiftList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 添加奖品
        submitForm(){
            this.$refs.giftForm.validate((valid) => {
                if (valid) {
                this.btnLoading = true;
                this.giftForm.activity_id = this.activity_id
                common.connect("/rafflev1/activity/prizeStore",this.giftForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getGiftList();
                    this.image = [];
                    this.$refs.giftForm.resetFields();
                    this.btnLoading = false;
                    this.isAddGift = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        // 编辑奖品
        submitFormEdit(){
             this.$refs.giftForm.validate((valid) => {
                if (valid) {
                this.giftForm.id = this.editId
                this.btnLoading = true;
                common.connect("/rafflev1/activity/prizeUpdate",this.giftForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getGiftList();
                    this.image = [];
                    this.editId = -1;
                    this.$refs.giftForm.resetFields();
                    this.btnLoading = false;
                    this.isAddGift = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        delImageList(){
            this.image.splice(0,1)
        },
        // 编辑
        handleEdit(row){
            this.editId = row.id;
            this.isAddGift = true;
            this.addLoading = true
            common.connect("/rafflev1/activity/prizeEdit",{id: row.id},(res)=>{
                this.image[0] = res.data.image;
                this.giftForm.name = res.data.name;
                this.giftForm.image = res.data.image;
                this.giftForm.quota = res.data.quota;
                this.addLoading = false
            });
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getGiftList();
        },
        handleCloseGift(){
            this.isAddGift = false;
        },
    },
}
</script>

<style lang='scss'>
.giftInfo{
    .btn{
        margin-bottom: 15px;
    }
    .image{
        height: 40px;
        vertical-align: bottom;
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 600px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
}
</style>
