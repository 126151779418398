<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-04-19 08:47:28
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-13 15:48:25
-->
<template>
    <div class="whiteList">
        <div class="ex-wrap">
            <el-button @click="batchesDelete" type="danger" plain>批量删除</el-button>
            <el-button class="btn" :loading="isDowingCase" @click="caseForm" type="warning" plain>下载模板</el-button>
            <el-upload
                ref="upload"
                class="upload-demo"
                action
                :limit="1"
                :show-file-list="false"
                :http-request="handleSuccess"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                >
                <el-button plain :loading="bloading" class="btn" type="success">导入名单</el-button>
            </el-upload>
            <el-button class="btn" type="primary" @click="isAddWhite = true">添加</el-button>
        </div>
        <el-table v-loading="loading" border :data="tableData" style="width: 100%;" 
            :row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
            <el-table-column align="center" type="selection" width="55"></el-table-column>
            <el-table-column align="center" label="姓名" prop="name"></el-table-column>
            <el-table-column align="center" label="券码" prop="mobile"></el-table-column>
        </el-table>
        <div class="pagination-wrap">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="10"
                layout="total, prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <!-- 添加 -->
        <el-dialog
            :before-close="handleCloseWhite" 
            :close-on-click-modal="false"
            :visible.sync="isAddWhite" 
            custom-class="dialog-wrap">
            <div class="title">添加客户</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="whiteForm" :rules="rulesWhite" ref="whiteForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="客户姓名" prop="name">
                        <el-input v-model="whiteForm.name" placeholder="请输入客户姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="客户券码" prop="mobile">
                        <el-input v-model="whiteForm.mobile" placeholder="请输入客户电话"></el-input>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button :loading="btnLoading" type="primary" @click="submitForm">添加</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import common from "@/utils/common.js"
export default {
    data () {
        return {
            tableData: [],
            idsList: [],
            isDowingCase: false,
            addLoading: false,
            isAddWhite: false,
            btnLoading: false,
            bloading: false,
            loading: false,
            whiteForm: {
                mobile: '',
                name: ''
            },
            rulesWhite: {
                name: [
                    { required: true, message: '请输入客户姓名', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: '请输入客户券码', trigger: 'blur' }
                ],
            },
            currentPage: 1,
            total: 0,
            infoId: '',

        }
    },
    mounted () {
        this.infoId = localStorage.getItem("infoId");
        this.getWhiteList();
    },
    methods: {
        // 添加客户
        submitForm(){
            this.$refs.whiteForm.validate((valid) => {
                if (valid) {
                this.btnLoading = true;
                this.whiteForm.activity_id = this.infoId;
                common.connect("/rafflev1/activity/listStore",this.whiteForm,(res)=>{
                    if(res.isSuccess == 1){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.getWhiteList();
                        this.$refs.whiteForm.resetFields();
                        this.btnLoading = false;
                        this.isAddWhite = false;
                    }else{
                        this.btnLoading = false;
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleCloseWhite(){
            this.isAddWhite = false;
        },
        // 导入
        handleSuccess(file){
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            let formData = new FormData();
            formData.append('activity_id',this.infoId);
            formData.append('file',file.file);
            this.bloading = true;
            let url = common.apiServer+'/rafflev1/activity/import'
            axios({
                url: url,
                data: formData,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': authorization,
                }
            }).then((res)=> {
                if(res.data.isSuccess == 1){
                    this.$message.success(res.data.msg);
                    this.getWhiteList();
                }else{
                    this.$message.error(res.data.msg);
                }
                this.$refs.upload.clearFiles()
                this.bloading = false;
            })
        },
        // 下载案例
        caseForm(){
            let a = document.createElement("a");
            a.href = "/static/listTemplate.xls";
            a.download = "导入白名单模板.xls";
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
        },
        // 批量删除
        batchesDelete(){
            if(this.idsList.length <= 0){
                this.$message.warning('请选择要删除的名单')
            }else{
                this.$confirm('是否确定批量删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let list = [];
                    this.idsList.forEach(v=>{
                        list.push(v.id)
                    })
                    let params = {
                        ids: JSON.stringify(list)
                    }
                    common.connect('/rafflev1/activity/listDelete',params,(res)=>{
                        this.getWhiteList();
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        },
        // 判断
        tableRowClassName({row, rowIndex}){
            if(row.status === 3){
                return 'info-row';
            }
        },
        // 多选
        handleSelectionChange(val){
            this.idsList = val;
        },
        // 获取列表数据
        getWhiteList(){
            this.loading = true;
            let params = {
                id: this.infoId,
                page: this.currentPage
            }
            common.connect("/rafflev1/activity/listIndex",params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getWhiteList();
        },
    },
}
</script>

<style lang='scss'>
.whiteList{
    .ex-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 15px;
        .btn{
            margin-right: 10px;
        }
    }
    .dialog-wrap{
        width: 700px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 550px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 5px;
    }
}
</style>
