<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-04-19 08:38:49
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 10:23:31
-->
<template>
    <div class="info-wrap">
        <div class="title-wrap">
            <el-page-header @back="goBack" content="活动详情"></el-page-header>
        </div>
        <div class="case-wrap">
            <!-- <div class="play">
                <el-button type="success" :loading="loaidng"
                    @click="handlePlay">开始抽奖</el-button>
            </div> -->
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="白名单" name="first">
                    <span slot="label">
                        <i style="font-size: 20px;color: #000" class="el-icon-user"></i>
                    </span>
                    <whiteList ref="whiteList"></whiteList>
                </el-tab-pane>
                <el-tab-pane label="奖品" name="second">
                    <giftInfo ref="giftInfo"></giftInfo>
                </el-tab-pane>
                <!-- <el-tab-pane label="中奖名单" name="thirdly">
                    <luckyList ref="luckyList"></luckyList>
                </el-tab-pane> -->
            </el-tabs>
        </div>
    </div>
</template>

<script>
import giftInfo from './components/giftInfo'
import whiteList from './components/whiteList'
export default {
    components:{
        giftInfo,
        whiteList,
    },
    data () {
        return {
            activeName: 'second',
            loaidng: false
        }
    },
    mounted () {
        
    },
    methods: {
        goBack(){
            this.$router.back() 
        }
    },
}
</script>

<style lang='scss'>
.info-wrap{
    background: rgb(240, 242, 245);
    // padding: 0 25px;
    height: 100vh;
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px 25px;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        border-radius: 6px;
        position: relative;
        margin: 0 25px;
        .play{
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 99;
        }
    }
}
</style>
